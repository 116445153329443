<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.32 6.61868L14.0801 5.85863C14.6394 5.29934 14.6394 4.38872 14.0801 3.82943L13.0691 2.81842C12.5098 2.25913 11.5991 2.25913 11.0399 2.81842L10.2798 3.57847L13.32 6.61868ZM9.26162 4.58948L2.67211 11.1862V14.2264H5.71232L12.3018 7.63687L9.26162 4.58948ZM13.4276 11.7168C13.4276 13.2871 11.6063 14.2264 9.84242 14.2264C9.44805 14.2264 9.12539 13.9037 9.12539 13.5093C9.12539 13.115 9.44805 12.7923 9.84242 12.7923C10.9466 12.7923 11.9935 12.2689 11.9935 11.7168C11.9935 11.3798 11.6493 11.093 11.1116 10.8563L12.1728 9.79513C12.94 10.2469 13.4276 10.8492 13.4276 11.7168ZM3.08799 8.74109C2.39247 8.33956 1.95508 7.81612 1.95508 7.05607C1.95508 5.76542 3.31027 5.17028 4.50771 4.64685C5.24625 4.31701 6.25726 3.87245 6.25726 3.47092C6.25726 3.17693 5.69798 2.75389 4.8232 2.75389C3.91974 2.75389 3.53255 3.19127 3.51103 3.21279C3.26007 3.50677 2.80834 3.54262 2.50719 3.29883C2.36341 3.18074 2.27162 3.011 2.25151 2.82602C2.2314 2.64105 2.28458 2.45556 2.39964 2.30933C2.47851 2.20894 3.21705 1.31982 4.8232 1.31982C6.42935 1.31982 7.69132 2.2663 7.69132 3.47092C7.69132 4.81176 6.30745 5.42124 5.08133 5.95901C4.40732 6.253 3.38914 6.69756 3.38914 7.05607C3.38914 7.27835 3.69746 7.48629 4.15636 7.67272L3.08799 8.74109Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPenCurved'
}
</script>
